

export const AwardIcon = ({classNames = ""}) => (
    <svg className={`icon award-icon ${classNames}`} viewBox="0 0 24 24">
        <path d="m14,9.5c0,1.103-.897,2-2,2s-2-.897-2-2,.897-2,2-2,2,.897,2,2Zm7.168-1.283c-.239-.431-.557-.761-.918-.995-.644-.416-.987-1.181-.817-1.928.097-.429.091-.897-.048-1.381-.246-.859-.946-1.559-1.805-1.805-.484-.139-.952-.145-1.38-.048-.748.17-1.513-.172-1.929-.817-.233-.361-.564-.679-.994-.918-.782-.433-1.771-.433-2.553,0-.431.239-.761.557-.994.918-.416.644-1.181.987-1.929.817-.428-.097-.896-.091-1.38.048-.859.246-1.559.946-1.805,1.805-.139.484-.145.953-.048,1.381.17.748-.173,1.513-.817,1.928-.362.233-.679.564-.918.995-.219.394-.325.842-.323,1.289-.002.447.105.894.323,1.289.239.431.557.761.918.995.644.416.987,1.181.817,1.928-.097.429-.091.897.048,1.382.246.859.946,1.559,1.805,1.805.484.139.952.145,1.38.048.748-.17,1.513.172,1.929.817.233.361.563.679.994.918.782.433,1.772.433,2.553,0,.431-.239.761-.557.994-.918.416-.644,1.181-.987,1.929-.817.428.097.896.091,1.38-.048.859-.246,1.559-.946,1.805-1.805.139-.484.145-.953.048-1.382-.17-.748.173-1.513.817-1.928.362-.233.679-.564.918-.995.219-.394.325-.842.323-1.289.002-.447-.105-.894-.323-1.289Zm-9.179,5.285c-2.206,0-4-1.794-4-4s1.794-4,4-4,4,1.794,4,4-1.794,4-4,4Zm-3.888,5.43c-.726.142-1.496.106-2.231-.105-1.426-.408-2.576-1.506-3.076-2.894l-2.306,2.223c-.972.938-.405,2.584.939,2.723l1.765.182.218,1.568c.179,1.291,1.751,1.828,2.683.917l3.401-3.296c-.553-.355-1.033-.784-1.393-1.318Zm6.406,1.318l3.401,3.296c.932.911,2.504.374,2.683-.917l.218-1.568,1.765-.182c1.344-.139,1.911-1.785.939-2.723l-2.306-2.223c-.5,1.388-1.65,2.486-3.076,2.894-.735.211-1.505.247-2.231.105-.36.534-.84.963-1.393,1.318Z" />
    </svg>
);

export const EducationIcon = ({classNames = ""}) => (
    <svg className={`icon education-icon ${classNames}`} viewBox="0 -960 960 960"><path d="M480-120 200-272v-240L40-600l440-240 440 240v320h-80v-276l-80 44v240L480-120Zm0-332 274-148-274-148-274 148 274 148Zm0 241 200-108v-151L480-360 280-470v151l200 108Zm0-241Zm0 90Zm0 0Z"/></svg>
);

export const WorkIcon = ({classNames = ""}) => (
    <svg className={`icon work-icon ${classNames}`} viewBox="0 -960 960 960"><path d="M160-120q-33 0-56.5-23.5T80-200v-440q0-33 23.5-56.5T160-720h160v-80q0-33 23.5-56.5T400-880h160q33 0 56.5 23.5T640-800v80h160q33 0 56.5 23.5T880-640v440q0 33-23.5 56.5T800-120H160Zm0-80h640v-440H160v440Zm240-520h160v-80H400v80ZM160-200v-440 440Z"/></svg>
);